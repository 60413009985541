/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';

// eslint-disable-next-line max-statements
const processData = data => {
  const currentDate = moment();
  const allDates = [];
  let fromDate = {};

  if (data.length > 0) {
    fromDate = moment(data[0].date);
  }
  else {
    fromDate = moment(currentDate).subtract(1, 'days');
  }

  const toDate = moment(currentDate).subtract(1, 'days');

  if (data.length !== 0) {
    while (fromDate <= toDate) {
      const tmpDate = fromDate;
      const obj = data.find(({date}) => moment(date).format('YYYY-MM-DD') === moment(tmpDate).format('YYYY-MM-DD'));

      const score = typeof obj === 'undefined' ? 0 : obj.avgScore;

      allDates.push({
        id: null,
        date: moment(fromDate).format('YYYY-MM-DD'),
        score: score
      });
      fromDate = moment(fromDate).add(1, 'days');
    }
  }

  return allDates;
};

// eslint-disable-next-line max-statements
const Medications = ({data}) => {
  const processedData = processData(data);
  const date = moment.now();
  const greenArray = [], yellowArray = [], redArray = [], greyArray = [], defaultArray = [];

  for (let i = 0; i < processedData.length; i++) {
    if (processedData[i].score === 4) {
      greenArray.push(processedData[i].date);
    }
    else if (processedData[i].score === 3) {
      yellowArray.push(processedData[i].date);
    }
    else if (processedData[i].score === 2) {
      redArray.push(processedData[i].date);
    }
    else if (processedData[i].score === 1) {
      greyArray.push(processedData[i].date);
    }
    else {
      defaultArray.push(processedData[i].date);
    }
  }

  return (
    <div className='cell small-5'>
      <div className='grid-x'>
        <div className='cell small-4 graph-header'>
          <h5 className='color-medication'>Medications</h5>
          <div className='legend'>
            <div className='row'>
              <span className='box green-box'/><p>Yes</p>
            </div>
            <div className='row'>
              <span className='box yellow-box'/><p>Not all of it</p>
            </div>
            <div className='row'>
              <span className='box red-box'/><p>No</p>
            </div>
            <div className='row'>
              <span className='box grey-box'/><p>I don&apos;t remember</p>
            </div>
          </div>
        </div>
        <div className='cell small-6'>
          <Flatpickr
            onDayCreate={(dObj, dStr, fp, dayElem) => {
              if (greenArray.indexOf(moment(dayElem.dateObj).format('YYYY-MM-DD')) !== -1) {
                dayElem.className += ' green-box';
              }
              else if (yellowArray.indexOf(moment(dayElem.dateObj).format('YYYY-MM-DD')) !== -1) {
                dayElem.className += ' yellow-box';
              }
              else if (redArray.indexOf(moment(dayElem.dateObj).format('YYYY-MM-DD')) !== -1) {
                dayElem.className += ' red-box';
              }
              else if (greyArray.indexOf(moment(dayElem.dateObj).format('YYYY-MM-DD')) !== -1) {
                dayElem.className += ' grey-box';
              }
            }}
            options={{
              inline: true,
              minDate: processedData.length > 0 ? processedData[0].date : moment().format('YYYY-MM-DD'),
              maxDate: moment().format('YYYY-MM-DD')
            }}
            value={date}
          />
        </div>
      </div>
    </div>
  );
};

Medications.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Medications;