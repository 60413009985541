import React from 'react';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, Area, Bar, ComposedChart
} from 'recharts';
import PropTypes from 'prop-types';
import moment from 'moment';
import ScoreDetails from './ScoreDetails';

const CustomTooltip = ({active, payload, label}) => {
  if (active) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>{`${label}: ${payload && payload[0].payload.toolTipDate}`}</p>
      </div>
    );
  }

  return null;
};

// eslint-disable-next-line max-statements
const processData = (data, duration) => {
  const currentDate = moment();
  const allDates = [];
  let fromDate = {};
  const toDate = moment(currentDate).subtract(1, 'days');

  if (duration === 'WEEK') {
    fromDate = moment(toDate).subtract(6, 'days');
  }

  if (duration === 'MONTH') {
    fromDate = moment(toDate).subtract(1, 'months');
  }

  if (data.length !== 0) {
    while (fromDate <= toDate) {
      const tmpDate = fromDate;
      const obj = data.find(({date}) => moment(date).format('YYYY-MM-DD') === moment(tmpDate).format('YYYY-MM-DD'));

      const score = typeof obj === 'undefined' ? null : obj.avgScore;
      const count = typeof obj === 'undefined' ? 0 : obj.responseCount;

      allDates.push({
        id: null,
        date: moment(fromDate).format('ddd'),
        toolTipDate: moment(fromDate).format('LL'),
        avgScore: score,
        responseCount: count
      });
      fromDate = moment(fromDate).add(1, 'days');
    }
  }

  return allDates;
};

const findAvg = data => {
  let sum = 0;
  let count = 0;

  for (let i = 0; i < data.length; i++) {
    if (data[i].avgScore === null) {
      sum += 0;
    }
    else {
      sum += parseFloat(data[i].avgScore);
      count++;
    }
  }

  if (count === 0) {
    return 0;
  }

  return (sum / count).toFixed(2);
};

const totalCount = data => {
  let count = 0;

  for (let i = 0; i < data.length; i++) {
    count += data[i].responseCount;
  }

  return count;
};

// eslint-disable-next-line arrow-body-style
const Graph = ({areaShade, category, data, duration, setResults, setResponseCount, setScores}) => {
  const processedData = processData(data, duration);
  const responseCount = totalCount(processedData);
  const scoreAvg = findAvg(processedData);
  setResponseCount(responseCount);
  setScores(parseFloat(scoreAvg));

  return (
    <div className='full-width graph-cell'>
      <div className='grid-x'>
        <div className='cell large-2 graph-header'>
          <h5 className={`color-${category.toLowerCase()}`}>{category}</h5>
          <ScoreDetails
            data={data}
            duration={duration}
            responseCount={responseCount}
            setResults={setResults}
          />
        </div>
        <div className='cell large-8 graph-container'>
          <span className='top-positioned'>GREAT</span>
          <ComposedChart
            data={processedData}
            height={140}
            margin={{
              top: 0, right: 5, left: 10, bottom: 15
            }}
            width={700}
          >
            <XAxis
              dataKey='date'
              offset={10}
              orientation='top'
              textAnchor='middle'
              xAxisId={1}
            />
            <XAxis
              dataKey='responseCount'
              height={35}
              label={{value: '# OF RESPONSES', position: 'insideBottomLeft', offset: -5}}
              orientation='bottom'
              textAnchor='middle'
              xAxisId={0}
            />
            <YAxis
              dataKey='avgScore'
              domain={[0, 4]}
              label={{value: 'SCORE', angle: -90, position: 'center', offset: 0}}
              type='number'
            />
            <Bar
              dataKey='avgScore'
              fill={areaShade}
            />
            <Area
              // activeDot={{r: 8}}
              dataKey='avgScore'
              // dot={{stroke: areaShade, fill: areaShade, r: 6}}
              fill={areaShade}
              // isAnimationActive={false}
              stroke={areaShade}
              type='linear'
            />
            <Tooltip
              content={<CustomTooltip/>}
              filterNull={false}
            />
            <CartesianGrid strokeDasharray='1 1'/>
          </ComposedChart>
          <span className='bottom-positioned'>POOR</span>
        </div>
        <div className='cell large-2 graph-scores'>
          <div className='scores'>
            <h6>Score Average <span>{scoreAvg}</span></h6>
            <h6>Responses <span>{responseCount}</span></h6>
          </div>
        </div>
      </div>
    </div>
  );
};

Graph.propTypes = {
  areaShade: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  duration: PropTypes.string.isRequired,
  setResponseCount: PropTypes.func.isRequired,
  setResults: PropTypes.func.isRequired,
  setScores: PropTypes.func.isRequired,
  showTopAxis: PropTypes.bool.isRequired
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.number,
  payload: PropTypes.array
};

export default Graph;