import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// eslint-disable-next-line max-statements
const getRangedData = (fromDate, toDate, data) => {
  let tempFromDate = fromDate;
  const allDates = [];

  if (data.length !== 0) {
    while (tempFromDate <= toDate) {
      const tmpDate = tempFromDate;
      const obj = data.find(({date}) => moment(date).format('YYYY-MM-DD') === moment(tmpDate).format('YYYY-MM-DD'));

      const score = typeof obj === 'undefined' ? null : obj.avgScore;
      const count = typeof obj === 'undefined' ? 0 : obj.responseCount;

      allDates.push({
        id: null,
        date: moment(tempFromDate).format('dddd, MMMM Do YYYY, h:mm:ss a'),
        avgScore: score,
        responseCount: count
      });
      tempFromDate = moment(tempFromDate).add(1, 'days');
    }
  }

  return allDates;
};

const getDataAverage = data => {
  let sum = 0;
  let responseCount = 0;

  for (let i = 0; i < data.length; i++) {
    if (data[i].avgScore === null) {
      sum += 0;
    }
    else {
      sum += parseFloat(data[i].avgScore);
      responseCount++;
    }
  }

  if (responseCount === 0) {
    return 0;
  }

  return (sum / responseCount).toFixed(2);
};

const getResultWordings = avgScore => {
  const score = parseFloat(avgScore);

  if (score >= parseFloat(3.5) && score <= parseFloat(4)) {
    return 'Great';
  }
  else if (score >= parseFloat(2.5) && score < parseFloat(3.5)) {
    return 'Good';
  }
  else if (score >= parseFloat(1.5) && score < parseFloat(2.5)) {
    return 'Fair';
  }
  else if (score >= parseFloat(1) && score < parseFloat(1.5)) {
    return 'Poor';
  }

  return 'Not Calculated';
};

// eslint-disable-next-line max-statements
const ScoreDetails = ({data, duration, setResults, responseCount}) => {
  const currentDate = moment();
  let oneUnitAgoAverage = 0;
  let twoUnitsAgoAverage = 0;
  let averageDifference = 0;
  let resultString = '';
  let resultWording = '';

  if (duration === 'WEEK') {
    let lastWeekData = [];
    let secondLastWeekData = [];
    const lastWeekEndDate = moment(currentDate).subtract(1, 'days');
    const lastWeekStartDate = moment(currentDate).subtract(7, 'days');

    lastWeekData = getRangedData(lastWeekStartDate, lastWeekEndDate, data);
    oneUnitAgoAverage = getDataAverage(lastWeekData);

    const secondLastWeekEndDate = moment(currentDate).subtract(8, 'days');
    const secondLastWeekStartDate = moment(currentDate).subtract(14, 'days');

    secondLastWeekData = getRangedData(secondLastWeekStartDate, secondLastWeekEndDate, data);
    twoUnitsAgoAverage = getDataAverage(secondLastWeekData);
    averageDifference = oneUnitAgoAverage - twoUnitsAgoAverage;
  }
  else if (duration === 'MONTH') {
    let lastMonthData = [];
    let secondLastMonthData = [];
    const lastMonthEndDate = moment(currentDate).subtract(1, 'days');
    const lastMonthStartDate = moment(currentDate).subtract(1, 'months');

    lastMonthData = getRangedData(lastMonthStartDate, lastMonthEndDate, data);
    oneUnitAgoAverage = getDataAverage(lastMonthData);

    const secondLastMonthEndDate = moment(lastMonthStartDate).subtract(1, 'days');
    const secondLastMonthStartDate = moment(currentDate).subtract(2, 'months');

    secondLastMonthData = getRangedData(secondLastMonthStartDate, secondLastMonthEndDate, data);
    twoUnitsAgoAverage = getDataAverage(secondLastMonthData);
    averageDifference = oneUnitAgoAverage - twoUnitsAgoAverage;
  }

  if (averageDifference > 0) {
    if (getResultWordings(twoUnitsAgoAverage) === getResultWordings(oneUnitAgoAverage)) {
      resultString = 'Remained the same';
      resultWording = getResultWordings(twoUnitsAgoAverage);
    }
    else {
      resultString = 'Improved';
      resultWording = `${getResultWordings(twoUnitsAgoAverage)} to ${getResultWordings(oneUnitAgoAverage)}`;
    }
  }
  else if (averageDifference < 0) {
    if (getResultWordings(twoUnitsAgoAverage) === getResultWordings(oneUnitAgoAverage)) {
      resultString = 'Remained the same';
      resultWording = getResultWordings(twoUnitsAgoAverage);
    }
    else {
      resultString = 'Worsened';
      resultWording = `${getResultWordings(twoUnitsAgoAverage)} to ${getResultWordings(oneUnitAgoAverage)}`;
    }
  }
  else {
    resultString = 'Remained the same';
    resultWording = getResultWordings(twoUnitsAgoAverage);
  }

  if (responseCount === 0) {
    resultString = 'No Responses';
    resultWording = '';
  }

  setResults(`${resultString}: ${resultWording} (${twoUnitsAgoAverage} to ${oneUnitAgoAverage})`);

  return (
    <div>
      <h6>{resultString}</h6>
      <h6>{resultWording}</h6>
    </div>
  );
};

ScoreDetails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  duration: PropTypes.string.isRequired,
  responseCount: PropTypes.number.isRequired,
  setResults: PropTypes.func.isRequired
};

export default ScoreDetails;