/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {notify} from 'react-notify-toast';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const medicationResults = (data, duration) => {
  const currentDate = moment();
  const allDates = [];
  let fromDate = {};

  if (data.length > 0) {
    if (duration === 'WEEK') {
      fromDate = moment(currentDate).subtract(7, 'days');
    }
    else if (duration === 'MONTH') {
      fromDate = moment(currentDate).subtract(1, 'months');
    }
  }
  else {
    fromDate = moment(currentDate).subtract(1, 'days');
  }

  const toDate = moment(currentDate).subtract(1, 'days');

  if (data.length !== 0) {
    while (fromDate <= toDate) {
      const tmpDate = fromDate;
      const obj = data.find(({date}) => moment(date).format('YYYY-MM-DD') === moment(tmpDate).format('YYYY-MM-DD'));

      const score = typeof obj === 'undefined' ? 0 : obj.avgScore;
      let description = '';

      if (score === 4) {
        description = 'Yes';
      }
      else if (score === 3) {
        description = 'Not all of it';
      }
      else if (score === 2) {
        description = 'No';
      }
      else if (score === 1) {
        description = 'I don\'t remember';
      }
      else {
        description = 'No Input';
      }

      allDates.push({
        id: null,
        date: moment(fromDate).format('YYYY-MM-DD'),
        score,
        description
      });
      fromDate = moment(fromDate).add(1, 'days');
    }
  }

  return allDates;
};

const videoResults = (data, duration) => {
  const currentDate = moment();
  let totalViewed = 0;
  let moodSupport = 0;
  let socialBoost = 0;
  let thoughtChallenge = 0;
  let relax = 0;
  let fromDate = {};
  const toDate = moment(currentDate).subtract(1, 'days');

  if (duration === 'WEEK') {
    fromDate = moment(toDate).subtract(7, 'days');
  }

  if (duration === 'MONTH') {
    fromDate = moment(toDate).subtract(1, 'months');
  }

  if (data.length !== 0) {
    // eslint-disable-next-line max-len
    const requiredData = data.filter(({createdAt}) => moment(createdAt).format('YYYY-MM-DD') >= moment(fromDate).format('YYYY-MM-DD') && moment(createdAt).format('YYYY-MM-DD') <= moment(toDate).format('YYYY-MM-DD'));

    for (let i = 0; i < requiredData.length; i++) {
      totalViewed++;

      switch (requiredData[i].category) {
      case 'Mood Support':
        moodSupport++;
        break;
      case 'Social Boost':
        socialBoost++;
        break;
      case 'Thought Challenges':
        thoughtChallenge++;
        break;
      case 'Relax':
        relax++;
        break;
      default:
        break;
      }
    }
  }

  const videoCount = [];
  videoCount.push({
    totalViewed,
    moodSupport,
    socialBoost,
    thoughtChallenge,
    relax
  });

  return videoCount;
};

const Clipboard = ({
  appUsage,
  duration,
  moodResult,
  sleepResult,
  socialResult,
  voicesResult,
  userNotifications,
  socialScores,
  socialResponseCount,
  voicesScores,
  voicesResponseCount,
  sleepScores,
  sleepResponseCount,
  moodScores,
  moodResponseCount,
  medicationsData,
  videosData
}) => {
  const [clipped, setClipped] = useState(false);
  const timeRange1 = userNotifications.length === 0 ? 'Not Set' : userNotifications.find(({timeRange}) => timeRange === 'time_range1').category;
  const timeRange2 = userNotifications.length === 0 ? 'Not Set' : userNotifications.find(({timeRange}) => timeRange === 'time_range2').category;
  const timeRange3 = userNotifications.length === 0 ? 'Not Set' : userNotifications.find(({timeRange}) => timeRange === 'time_range3').category;
  const medicationResult = medicationResults(medicationsData, duration);
  const videoCount = videoResults(videosData, duration);
  let medicationsResultString = '';

  const toDate = moment().subtract(1, 'days');
  let fromDate = moment();

  if (duration === 'WEEK') {
    fromDate = moment().subtract(7, 'days');
  }
  else if (duration === 'MONTH') {
    fromDate = moment().subtract(1, 'months');
  }

  useEffect(() => {
    setClipped(false);
  });

  for (let i = 0; i < medicationResult.length; i++) {
    medicationsResultString += `${moment(medicationResult[i].date).format('dddd, MMMM Do')}: ${medicationResult[i].description}\n\t`;
  }

  const clipboardString =
    `Date: ${moment().format('LLL')}

Period: ${moment(fromDate).format('LL')} - ${moment(toDate).format('LL')}

# times used the app:
  ${appUsage.lastWeekCount} : past week
  ${appUsage.lastMonthCount} : past month
  ${appUsage.allTimeCount} : all time

# times responded to prompt:
  ${appUsage.lastWeekPromptPercentage}% : last week
  ${appUsage.lastMonthPromptPercentage}% : past month

Currently selected interventions:
  ${timeRange1}: 9am – 1pm
  ${timeRange2}: 1pm – 5pm
  ${timeRange3}: 5pm – 9pm

Scores this ${duration}:
Social:
  ${socialScores} out of 4: score average
  ${socialResponseCount}: responses

Voices:
  ${voicesScores} out of 4: score average
  ${voicesResponseCount}: responses

Sleep:
  ${sleepScores} out of 4: score average
  ${sleepResponseCount}: responses

Mood:
  ${moodScores} out of 4: score average
  ${moodResponseCount}: responses

Compared to last ${duration}:
  Social -- ${socialResult}
  Voices -- ${voicesResult}
  Sleep -- ${sleepResult}
  Mood -- ${moodResult}

Medication:
"Since yesterday, did you take your medications as prescribed?"
  ${medicationsResultString}

Toolbox videos Watched:
  ${videoCount[0].totalViewed}: total accesses
  ${videoCount[0].moodSupport}: mood support
  ${videoCount[0].socialBoost}: social boost
  ${videoCount[0].thoughtChallenge}: thought challenges
  ${videoCount[0].relax}: relax
`;

  return (
    <div className='cell small-4'>
      <CopyToClipboard
        onCopy={() => setClipped(true)}
        text={clipboardString}
      >
        <button
          className='button'
          type='button'
        >
          Copy to clipboard
        </button>
      </CopyToClipboard>
      {clipped ? notify.show('Copied to Clipboard', 'success') : null}
    </div>
  );
};

Clipboard.propTypes = {
  appUsage: PropTypes.object.isRequired,
  duration: PropTypes.string.isRequired,
  medicationsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  moodResponseCount: PropTypes.number.isRequired,
  moodResult: PropTypes.string.isRequired,
  moodScores: PropTypes.number.isRequired,
  sleepResponseCount: PropTypes.number.isRequired,
  sleepResult: PropTypes.string.isRequired,
  sleepScores: PropTypes.number.isRequired,
  socialResponseCount: PropTypes.number.isRequired,
  socialResult: PropTypes.string.isRequired,
  socialScores: PropTypes.number.isRequired,
  userNotifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  videosData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  voicesResponseCount: PropTypes.number.isRequired,
  voicesResult: PropTypes.string.isRequired,
  voicesScores: PropTypes.number.isRequired
};

export default Clipboard;