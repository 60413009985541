import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// eslint-disable-next-line max-statements
const VideoToolbox = ({data, duration}) => {
  const currentDate = moment();
  let totalViewed = 0;
  let moodSupport = 0;
  let socialBoost = 0;
  let thoughtChallenge = 0;
  let relax = 0;
  let fromDate = {};
  const toDate = moment(currentDate).subtract(1, 'days');

  if (duration === 'WEEK') {
    fromDate = moment(toDate).subtract(6, 'days');
  }

  if (duration === 'MONTH') {
    fromDate = moment(toDate).subtract(1, 'months');
  }

  if (data.length !== 0) {
    // eslint-disable-next-line max-len
    const requiredData = data.filter(({createdAt}) => moment(createdAt).format('YYYY-MM-DD') >= moment(fromDate).format('YYYY-MM-DD') && moment(createdAt).format('YYYY-MM-DD') <= moment(toDate).format('YYYY-MM-DD'));

    for (let i = 0; i < requiredData.length; i++) {
      totalViewed++;

      switch (requiredData[i].category) {
      case 'Mood Support':
        moodSupport++;
        break;
      case 'Social Boost':
        socialBoost++;
        break;
      case 'Thought Challenges':
        thoughtChallenge++;
        break;
      case 'Relax':
        relax++;
        break;
      default:
        break;
      }
    }
  }

  return (
    <div className='cell medium-7 toolbox-videos'>
      <h6>Toolbox Videos Watched</h6>
      <div className='grid-x grid-margin-x'>
        <div className='cell data-wrapper'>
          <div className='grid-x grid-margin-x'>
            <div className='cell large-auto'>
              <div className='video-count color-dark-gray '>
                <h6>TOTAL</h6>
                <h4>{totalViewed}</h4>
              </div>
            </div>
            <div className='cell large-auto'>
              <div className='video-count color-mood'>
                <h6>MOOD SUPPORT</h6>
                <h4>{moodSupport}</h4>
              </div>
            </div>
            <div className='cell large-auto'>
              <div className='video-count color-social'>
                <h6>SOCIAL BOOST</h6>
                <h4>{socialBoost}</h4>
              </div>
            </div>
            <div className='cell large-auto'>
              <div className='video-count color-dark-gray '>
                <h6>THOUGHT CHALLENGES</h6>
                <h4>{thoughtChallenge}</h4>
              </div>
            </div>
            <div className='cell large-auto'>
              <div className='video-count color-dark-gray '>
                <h6>RELAX</h6>
                <h4>{relax}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoToolbox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  duration: PropTypes.string.isRequired
};

export default VideoToolbox;