import React from 'react';
import spinner from '../../../assets/images/spinner.gif';

const Spinner = () =>
  <div style={{marginTop: '100px'}}>
    <img
      alt='Loading...'
      src={spinner}
      style={{margin: 'auto', display: 'block'}}
    />
  </div>;

export default Spinner;