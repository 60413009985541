import React from 'react';
import PropTypes from 'prop-types';

const UserInterventions = ({data}) => {
  const timeRange1 = data.find(({timeRange}) => timeRange === 'time_range1');
  const timeRange2 = data.find(({timeRange}) => timeRange === 'time_range2');
  const timeRange3 = data.find(({timeRange}) => timeRange === 'time_range3');

  if (data.length === 0) {
    return <h4>No Current Interventions Data</h4>;
  }

  return (
    <div className='grid-x'>
      <div className='cell small-2 large-auto grid-padding-x'>
        <p>9AM - 1PM</p>
        <p className={`color-${timeRange1.category.toLowerCase()}`}>
          <span className={`dot dot-${timeRange1.category.toLowerCase()}-background`}/>
          {timeRange1.category}
        </p>
      </div>
      <div className='cell small-2 large-auto grid-padding-x'>
        <p>1PM - 5PM</p>
        <p className={`color-${timeRange2.category.toLowerCase()}`}>
          <span className={`dot dot-${timeRange2.category.toLowerCase()}-background`}/>
          {timeRange2.category}
        </p>
      </div>
      <div className='cell small-2 large-auto grid-padding-x'>
        <p>5PM - 9PM</p>
        <p className={`color-${timeRange3.category.toLowerCase()}`}>
          <span className={`dot dot-${timeRange3.category.toLowerCase()}-background`}/>
          {timeRange3.category}
        </p>
      </div>
    </div>
  );
};

UserInterventions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default UserInterventions;