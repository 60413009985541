/* eslint-disable max-statements */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Graph from './Graph';
import VideoToolbox from '../video_toolbox/VideoToolbox';
import Medication from '../medications/Medications';
import Clipboard from '../clipboard/Clipboard';

// eslint-disable-next-line max-len
const Graphs = ({social, voices, sleep, mood, medication, videoPreferences, userNotifications, appUsage}) => {
  const [duration, setDuration] = useState('WEEK');
  const [socialResult, setSocialResult] = useState('Not Initialized');
  const [voicesResult, setVoicesResult] = useState('Not Initialized');
  const [sleepResult, setSleepResult] = useState('Not Initialized');
  const [moodResult, setMoodResult] = useState('Not Initialized');
  const [socialScores, setSocialScore] = useState(0);
  const [socialResponseCount, setSocialResponseCount] = useState(0);
  const [voicesScores, setVoicesScore] = useState(0);
  const [voicesResponseCount, setVoicesResponseCount] = useState(0);
  const [sleepScores, setSleepScore] = useState(0);
  const [sleepResponseCount, setSleepResponseCount] = useState(0);
  const [moodScores, setMoodScore] = useState(0);
  const [moodResponseCount, setMoodResponseCount] = useState(0);

  const buttonOneClassnames = ['button'];
  const buttonTwoClassnames = ['button'];

  if (duration === 'WEEK') {
    buttonTwoClassnames.push('white');
  }
  else {
    buttonOneClassnames.push('white');
  }

  return (
    <div className='container'>
      <div className='grid-x'>
        <div className='cell small-4'>
          <button
            className={buttonOneClassnames.join(' ')}
            onClick={() => {
              setDuration('WEEK');
            }}
            type='button'
          >
            PAST WEEK
          </button>
          <button
            className={buttonTwoClassnames.join(' ')}
            onClick={() => {
              setDuration('MONTH');
            }}
            type='button'
          >
            PAST MONTH
          </button>
        </div>
        <Clipboard
          appUsage={appUsage}
          duration={duration}
          medicationsData={medication}
          moodResponseCount={moodResponseCount}
          moodResult={moodResult}
          moodScores={moodScores}
          sleepResponseCount={sleepResponseCount}
          sleepResult={sleepResult}
          sleepScores={sleepScores}
          socialResponseCount={socialResponseCount}
          socialResult={socialResult}
          socialScores={socialScores}
          userNotifications={userNotifications}
          videosData={videoPreferences}
          voicesResponseCount={voicesResponseCount}
          voicesResult={voicesResult}
          voicesScores={voicesScores}
        />
        <Graph
          areaShade='#96c7ee'
          category='Social'
          data={social}
          duration={duration}
          setResponseCount={setSocialResponseCount}
          setResults={setSocialResult}
          setScores={setSocialScore}
          showTopAxis
        />
        <Graph
          areaShade='#e7a77e'
          category='Voices'
          data={voices}
          duration={duration}
          setResponseCount={setVoicesResponseCount}
          setResults={setVoicesResult}
          setScores={setVoicesScore}
          showTopAxis={false}
        />
        <Graph
          areaShade='#b3a1ca'
          category='Sleep'
          data={sleep}
          duration={duration}
          setResponseCount={setSleepResponseCount}
          setResults={setSleepResult}
          setScores={setSleepScore}
          showTopAxis={false}
        />
        <Graph
          areaShade='#f3d07f'
          category='Mood'
          data={mood}
          duration={duration}
          setResponseCount={setMoodResponseCount}
          setResults={setMoodResult}
          setScores={setMoodScore}
          showTopAxis={false}
        />
        <div className='full-width'>
          <div className='grid-x medications-row'>
            <Medication
              data={medication}
            />
            <VideoToolbox
              data={videoPreferences}
              duration={duration}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Graphs.propTypes = {
  appUsage: PropTypes.object.isRequired,
  medication: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mood: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sleep: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  social: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userNotifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  videoPreferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  voices: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Graphs;